import React, { useState } from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// plugin that creates slider
//import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from '@material-ui/core/';

// core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Accordion from "components/Accordion/Accordion.js";
// import CardBody from "components/Card/CardBody.js";



import styles from "assets/jss/material-kit-pro-react/views/presentationSections/sliderStyle.js";

import './Slider.css'
const useStyles = makeStyles(styles);



export default function SectionProducts() {

  const [priceCollaborateurUnit, setPriceCollaborateurUnit] = useState();
  const [pricePublicUnit, setPricePublicUnit] = useState();
 
  const [discountUnit, setDiscountUnit] = useState();
  const [tva, setTvaUnit] = useState(20);

  const changePriceCollaborateur = (event) => {
    setPriceCollaborateurUnit(event.target.value);
    //console.log(event.target.value)
    sessionStorage.setItem("setPriceCollaborateur", event.target.value);
  };
  const changePricePublic = (event) => {
    setPricePublicUnit(event.target.value);
    //console.log(event.target.value)
    sessionStorage.setItem("setPricePublic", event.target.value);
  };
  const changeDiscount = (event) => {
    setDiscountUnit(event.target.value);
    //console.log(event.target.value)
    sessionStorage.setItem("setDiscount", event.target.value);
  };
   const changeTva = (event) => {
    setTvaUnit(event.target.value);
     sessionStorage.setItem("setTva", event.target.value);
   };

  const classes = useStyles();
  return (
    <div className={classes.section_}>
      <div className='box-container'>
        <h5 className={classes.textBlue}>Collaborateur : {priceCollaborateurUnit} € HT</h5>
        <TextField className={classes.input}
          type="text"
          onChange={changePriceCollaborateur}
          placeholder="0"
        /*   min={1}
          max={250}
          step={1} */
          value={priceCollaborateurUnit}
        />  
         <TextField className={classes.input}
          type="text"
          value={priceCollaborateurUnit*1.2}     
        />€ TTC        
        <br /><br /> <br />
        <h5 className={classes.textBlue}>Public : {pricePublicUnit} € HT</h5>
        <TextField className={classes.input}
          type="text"
          onChange={changePricePublic}
          placeholder="0"
       /*    min={1}
          max={500}
          step={1} */
          value={pricePublicUnit}
        />
        <TextField className={classes.input}
          type="text"
          value={pricePublicUnit*1.2}     
        />€ TTC
        <br /><br /> <br />
        <h5 className={classes.textBlue}>Discount : {discountUnit} en %</h5>
        <TextField className={classes.input}
          type="text"
          onChange={changeDiscount}
          placeholder="0"
        /*   min={1}
          max={200}
          step={1} */
          value={discountUnit}
        />
        <h6>Si Discount à 0 un seul prix en catalogue autrement un prix rayé et un prix discount</h6>
        <br /><br /> <br />        
        <h5 className={classes.textBlue}>Tva : {tva} en %</h5>
        <TextField className={classes.input}
          type="text"
         /*  onChange={changeTva} 
          min={5}
          max={20}
          step={0.1}*/
          value={tva}
          disabled
        /> 
        <br /><br />
      </div>
    </div>
  );
}
