import React, { useEffect, useState } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Checkbox from "@material-ui/core/Checkbox";
//import Tooltip from "@material-ui/core/Tooltip";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui icons
//import Favorite from "@material-ui/icons/Favorite";
//import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
//import Cached from "@material-ui/icons/Cached";
//import Subject from "@material-ui/icons/Subject";
//import Check from "@material-ui/icons/Check";
// core components
//import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
//import Clearfix from "components/Clearfix/Clearfix.js";



import photo1 from "assets/img/ia/mobilier1.jpeg";
import photo2 from "assets/img/ia/mobilier2.jpeg";
import photo3 from "assets/img/ia/mobilier3.jpeg";

//import dg3 from "assets/img/dg3.jpg";
//import dg1 from "assets/img/dg1.jpg";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionProducts() {
  const [isPub, setPub] = useState();
  const [isModal, setModal] = useState();

  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };
  const loadPub = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetPub.php`)
      .then(res => {
        if(res.data.success===true) {
          //console.log(res.data.textePub2);
          setPub(res.data.textePub2);
          return;         
          }          
      })
    } catch (error) { throw error; }
  };
  const loadModal = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetModal.php`)
        .then(res => {
          if(res.data.success===true) {
            setModal(res.data.modaltext);
            return;         
            }          
        })
    } catch (error) { throw error; }
  };
  const classes = useStyles();

  useEffect(() => {
    loadPub();
    loadModal();    
  }, []);

  return (
    <div className={classes.section}>
      <div className={classes.container}>

        <GridContainer>
          <GridItem md={4} sm={4}>
            <Card className={classes.shadowForCard_}>
            <div
      aria-label="eFLEX"
      role="img"
      style={{
        backgroundColor: '#fff',
        backgroundImage:  `url(${photo1})`,
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '0',
        paddingBottom: '80%',
        width: '100%',
      }}
    />
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  eFLEX
                </h6>
             
                  <h3 className={classes.cardTitle}>
                 {isModal}
                  </h3>
             
                <p className={classes.description}>
                 
                </p>
                {/* <Button href="#" round color="white">
                  <Subject /> Read
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={4} sm={4}>
             <Card className={classes.shadowForCard_}>
            <div
      aria-label="eFLEX"
      role="img"
      style={{
        backgroundColor: '#fff',
        backgroundImage:  `url(${photo2})`,
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '0',
        paddingBottom: '80%',
        width: '100%',
      }}
    />
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  Mobiliers

                </h6>
                
                  <h3 className={classes.cardTitle}>
                  {isPub}
                  </h3>
         
                <p className={classes.description}>

                </p>


                <Button round className={classes.buttonEflex}

                  onClick={() => smoothScroll('bottomSection')}
                >
                  <ArrowDownwardIcon />
                </Button>

                
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={4} sm={4}>
          <Card className={classes.shadowForCard_}>
            <div
      aria-label="eFLEX"
      role="img"
      style={{
        backgroundColor: '#fff',
        backgroundImage:  `url(${photo3})`,
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '0',
        paddingBottom: '80%',
        width: '100%',
      }}
    />
              <CardBody>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  Avantage Collaborateur
                </h6>
             
                  <h3 className={classes.cardTitle}>
                    Si vous êtes salarié d'une entreprise cliente, créez votre compte avec votre e-mail professionnel ET bénéficiez de conditions préférentielles
                  </h3>
              
                <p className={classes.description}>

                </p>

                <Link to="/inscription-page"> 
                <Button round className={classes.buttonEflex}>
                  <ArrowForwardIcon />
                </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
         {/*  <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${dg3})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  Tutorials
                </h6>
                <a href="#pablo">
                  <h3 className={classes.cardTitle}>Trending colors of 2020</h3>
                </a>
                <p className={classes.description}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </p>
                <Button href="#" round color="white">
                  <Subject /> Read
                </Button>
              </CardBody>
            </Card>
          </GridItem> */}
         {/*  <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${dg1})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  Productivity Style
                </h6>
                <a href="#pablo">
                  <h3 className={classes.cardTitle}>Fashion & Style 2020</h3>
                </a>
                <p className={classes.description}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </p>
                <Button href="#" round color="white">
                  <Subject /> Read
                </Button>
              </CardBody>
            </Card>
          </GridItem> */}
        </GridContainer>
      </div>
      <div id="bottomSection"></div>
    </div>
  );
}
