
import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import SaveIcon from '@material-ui/icons/Save';

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import { TextField } from '@material-ui/core/';

const useStyles = makeStyles(styles);

export default function Compteur(props) {
  const classes = useStyles();

  const [messageEmptyQuantite, resultEmptyQuantite] = useState([]);
  const [messageConfirmAction, resultMessageConfirmAction] = useState([]);

  const { id_std } = props

 
  const [nivfinResult, setNivfinResult] = useState([]);

  const [nivfinTemp, setNivfinToTemp] = useState([]);

  const nivfinResultOnLoad = function (id_std) {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eGetQteStd.php`, {
        id_std: id_std,
       id_pro: sessionStorage.getItem("getIdOwnerSelect"),
      })
        .then(res => {
       
          setNivfinResult(res.data.qteStdData.nivfinstock);
          return;
        })
    } catch (error) { throw error; }
  }

  
  let nivfinRes;//celle que l on affiche

  if (nivfinResult.length === 0) {
    nivfinResultOnLoad(id_std);
  }
  else {
    nivfinRes = nivfinResult;
   
  }


  const handleClick = (event) => {
    setNivfinToTemp(event.target.value);
  };
 

   const setQuantity = () => {
    // console.log(id_std, nivfin2Temp, 2);
    setQuantityItem(id_std, nivfinTemp);
  };

  const setQuantityItem = async (id, qte) => {
    console.log(id)
    console.log(qte)

    if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {

      if (qte!=='') {
        try {
          Axios.defaults.withCredentials = true;
          Axios.post(`${ApiConfig.baseUrl}/Controls/eAddItemQte.php`, {
            id_std: id,
            qte_std: qte,
            id_pro: sessionStorage.getItem("getIdOwnerSelect"),
          })
            .then(res => {
               console.log(res.data);

              if (res.data.success === true) {
                setNivfinResult(res.data.itemCountData.nivfinstock);
                setNivfinToTemp('');
                resultMessageConfirmAction(res.data.itemCountData.qteStd + ' element(s) ');// A voir si pour afficher un message apres validation
                resultEmptyQuantite('');
              }
              return;
            })
        } catch (error) { throw error; }
      }
      else {
        resultEmptyQuantite('Indiquez la quantité');
      }
    }
    
  };

  return <>
    <div className={classes.textCenter}>
      <br />
      <h6 className={classes.textWarning}>{messageEmptyQuantite}</h6>
      <h6 className={classes.textBlue}>{messageConfirmAction}</h6>

    </div>
    <div className={classes.mlAuto}>
      <span className={classNames(classes.price, classes.priceNew)}>
        Total: {nivfinRes}
      </span>
    </div>
    <GridContainer>
      <GridItem xs={4} sm={4} md={4}>       
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <span key={2}>
          <div className={classes.buttonGroup}>
          {/*   Fonctionnel: {nivfinRes} */}
            <br />
            {sessionStorage.getItem("statut_clt") === 'modeAdmin' ? 
            <>
            <TextField className={classes.input}
              type="text"
              onChange={handleClick}
              value={nivfinTemp}
            />
            <Button
              color="info"
              size="sm"
              round
              onClick={setQuantity}>
              <SaveIcon />
            </Button></>
             :
             <><hr></hr></>
            }
          </div>
        </span>
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>       
      </GridItem>
    </GridContainer>
  </>
}