/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import DefaultInput from "components/ToggleVisibility/DefaultInput.js";

import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionMarquePage() {

  const getMarqueProduct = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetMarqueProduct.php`)
        .then(res => {
         // console.log(res.data.marqueproductdata)
          setMarqueProduct(res.data.marqueproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const [isMarqueProduct, setMarqueProduct] = useState([]);
  const [marqueSelect, setMarqueSelect] = useState([]);

  sessionStorage.setItem("getMarqueSelect", marqueSelect);

  const classes = useStyles();
  useEffect(() => {
    getMarqueProduct();
  }, []);

  return (
    <div className={classNames(classes.main, classes.mainRaised_)}>
      <div className={classes.container_}>
        <label>GAMME </label><ToggleVisibilityAddInput>
          <DefaultInput
            valueString={'Gamme'}
          />
        </ToggleVisibilityAddInput>
        <FormControl
          fullWidth
          className={classes.selectFormControl}
        >
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={marqueSelect}
            onChange={event => setMarqueSelect(event.target.value)}
            inputProps={{
              name: "marqueSelect",
              id: "marqueSelect"
            }}
          >
            {isMarqueProduct.map((item) => (
              <MenuItem key={item.id_mrq}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={item.id_mrq}
              >
                {item.design_mrq}
              </MenuItem>
            ))}
          </Select>
        </FormControl>            
      </div>
    </div>
  );
}
