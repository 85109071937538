/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
//import Remove from "@material-ui/icons/Remove";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Badge from "components/Badge/Badge.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
import CachedIcon from '@material-ui/icons/Cached';
import ArtTrack from "@material-ui/icons/ArtTrack";
import RotateRightIcon from '@material-ui/icons/RotateRight';

//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";

import Muted from "components/Typography/Muted.js";
//import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

import SectionSliderUnit from "views/PresentationPage/Sections/SectionSliderUnit.js";

import SectionPriceProduct from "views/PresentationPage/Sections/SectionPriceProduct.js";

import SectionGroupe from "views/AddProductPage/Sections/SectionGroupe.js";
import SectionType from "views/AddProductPage/Sections/SectionType.js";//TYPE
import SectionColor from "views/AddProductPage/Sections/SectionColor.js";
import SectionMateriau from "views/AddProductPage/Sections/SectionMateriau.js";
import SectionFinition from "views/AddProductPage/Sections/SectionFinition.js";
import SectionFournisseur from "views/AddProductPage/Sections/SectionFournisseur.js";
import SectionMarque from "views/AddProductPage/Sections/SectionMarque.js";
//import SectionFabricant from "views/AddProductPage/Sections/SectionFabricant.js";
import Compteur from "views/CompteurPage/CompteurPage.js"
import SectionProprietaire from "views/AddProductPage/Sections/SectionProprietaire.js";
import SectionCategorie from "views/AddProductPage/Sections/SectionCategorie.js";

import Accordion from "components/Accordion/Accordion.js";

import ImageGallery from "react-image-gallery";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

import { TextField } from '@material-ui/core/';
import { TextareaAutosize } from '@material-ui/core/';

const useStyles = makeStyles(profilePageStyle);

export default function UpdateProductPage() {

  const history = useHistory();
  const [isitem, setitem] = useState([]);
  const [islibelle, setlibelle] = useState();

  /*CHARGE les caracteristiques du produit*/

  const getItemData = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eGetItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          //console.log(res.data.query)
          setitem(res.data.itemdata);
          setlibelle(res.data.libelle);
          return;
        })
    } catch (error) { throw error; }
  };

  const position = useRef(null);
  const [images, showPicture] = useState([]);

  //CONSTRUIT LE TABLEAU IMAGE GALLERY
  const getPicturesData = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eGetPictures.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          showPicture(res.data.picturedata);//alimente la gallery
          return;
        })
    } catch (error) { throw error; }
  };

  const [addproductInfo, setproductInfo] = useState({
    designationLabel: '',
  });

  const delItemData = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eDelItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          //console.log(res.data);
          if (res.data.success === true) {
            history.push("/catalogue-page");//REnvoyer vers la partie profil pour associer le compte A FAIRE
            // resultDeleteProduct('Votre produit est supprimé');
          }
          else {
            resultDeleteProduct('Identification du produit manquante');//poser le message sous lebouton
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const onChangeValue = (e) => {
    setlibelle(e.target.value);

    setproductInfo({
      ...addproductInfo,
      [e.target.name]: e.target.value
    });
  }

  const classes = useStyles();

  const [messageMaj, resultMajProduct] = useState([]);
  const [messageUpdate, resultUpdateProduct] = useState([]);
  const [messageNotDelete, resultDeleteProduct] = useState([]);
  const [messageUpdateQuality, resultUpdateQualityProduct] = useState([]);


  const updatePriceToProduct = async (event) => {
    if(!isNaN(+sessionStorage.getItem("setPriceCollaborateur"))==false){
      //console.log(!isNaN(+sessionStorage.getItem("setPriceCollaborateur"))); // true if its a number, false if not
      resultMajProduct('Erreur de saisie sur prix collaborateur');
    return false;
    }
    else if(!isNaN(+sessionStorage.getItem("setPricePublic"))==false){
      //console.log(!isNaN(+sessionStorage.getItem("setPriceCollaborateur"))); // true if its a number, false if not
      resultMajProduct('Erreur de saisie sur prix public');
    return false;
    }
    else if(!isNaN(+sessionStorage.getItem("setDiscount"))==false){
      //console.log(!isNaN(+sessionStorage.getItem("setPriceCollaborateur"))); // true if its a number, false if not
      resultMajProduct('Erreur de saisie sur discount');
    return false;
    }
    else{
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eUpdatePriceToProduct.php`, {
        productId: sessionStorage.getItem("id_std"),       
        priceCollaborateur: sessionStorage.getItem("setPriceCollaborateur"),
        pricePublic: sessionStorage.getItem("setPricePublic"),
        productDiscount: sessionStorage.getItem("setDiscount"),
        productTva: sessionStorage.getItem("setTva"),
      })
      .then(res => {
        if (res.data.updatepriceproductdata['success'] === true) {
          resultMajProduct('Les prix sont mis à jour');
        }
        else {
          resultMajProduct('Identification du produit manquante');
        }
      })
  } catch (error) { throw error; }}
};

  const updateToProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      // console.log(addproductInfo.designationLabel);//vide ou string
      // console.log(addproductInfo.descriptionArea);//undefined ou string

      // console.log(sessionStorage.getItem("setSelectedEnabled"));//undefined ou id
      // console.log(sessionStorage.getItem("setHeightUnit"));//null ou number
      // console.log(sessionStorage.getItem("setWidthUnit"));// null ou number
      // console.log(sessionStorage.getItem("setDepthUnit"));// null ou number
      // console.log(sessionStorage.getItem("setVolumeUnit"));// null ou number

      Axios.post(`${ApiConfig.baseUrl}/Controls/eUpdateToProduct.php`, {
        productId: sessionStorage.getItem("id_std"),
        productDesignation: addproductInfo.designationLabel,
        productGroup: sessionStorage.getItem("setSelectedEnabled"),
        productDescription: addproductInfo.descriptionArea,
        productHeight: sessionStorage.getItem("setHeightUnit"),
        productWidth: sessionStorage.getItem("setWidthUnit"),
        productDeph: sessionStorage.getItem("setDepthUnit"),
        productVolume: sessionStorage.getItem("setVolumeUnit"),
      })
        .then(res => {
          console.log(res.data)
          if (res.data.updateproductdata['success'] === true) {
            resultMajProduct('Votre produit est modifié');
          }
          else {
            resultMajProduct('Identification du produit manquante');
          }
        })
    } catch (error) { throw error; }
  };

  const addToProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      // console.log(sessionStorage.getItem("getColorSelect"));
      // console.log(sessionStorage.getItem("getMateriauSelect"));
      // console.log(sessionStorage.getItem("getFinitionSelect"));
      // console.log(sessionStorage.getItem("getFournisseurSelect"));
      // console.log(sessionStorage.getItem("getMarqueSelect"));
      // console.log(sessionStorage.getItem("getFabricantSelect"));

      Axios.post(`${ApiConfig.baseUrl}/Controls/eAddToProduct.php`, {
        idColor: sessionStorage.getItem("getColorSelect"),
        idMateriau: sessionStorage.getItem("getMateriauSelect"),
        idFinition: sessionStorage.getItem("getFinitionSelect"),
        idFournisseur: sessionStorage.getItem("getFournisseurSelect"),
        idMarque: sessionStorage.getItem("getMarqueSelect"),
       // idFabricant: sessionStorage.getItem("getFabricantSelect"),
        productId: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          console.log(res)
          if (res.data.productupdatedata.success === true) {
            resultUpdateProduct('Votre produit est mis à jour');
          }
          else if (res.data.productupdatedata.success === false) {
            resultUpdateProduct('Echec de la Maj des caractéristiques');
          }
        })
    } catch (error) { throw error; }
  };

  const getItemRotate = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eRotatePicture.php`, {
        id_std: id, position: position.current.state.currentIndex,
      })
        .then(res => {
          //console.log(res.data)
          getPicturesData();
          return;
        })
    } catch (error) { throw error; }
  };
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };


  const smoothScrollClickTarget = (e, target) => {
    e.preventDefault();
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };


  const addToQualityProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();

      Axios.post(`${ApiConfig.baseUrl}/Controls/eAddToQualityProduct.php`, {
        idOwner: sessionStorage.getItem("getOwnerSelect"),
        idCat: sessionStorage.getItem("geCategorieSelect"),
        productId: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          console.log(res.data)
          if (res.data.productqualityupdatedata.success === true) {
            resultUpdateQualityProduct('La fiche du produit est mise à jour');
          }
          else if (res.data.productqualityupdatedata.success === false) {
            resultUpdateQualityProduct('Echec de la Maj de la fiche produit');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    getItemData();
    getPicturesData();

  }, []);

  return (
    <div>
      {/*  <Header
        color="black"
        brand="EFLEX"
        links={<HeaderLinks dropdownHoverColor="whiteColor" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
 */}
      <br /><br />      <br /><br />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <br /><br /><br /><br /><br />
                <div className={classes.name}>
                  <h3 className={classes.title}>INTERFACE DE MAJ</h3>
                  {/* <h6>MODIFIER l' id {sessionStorage.getItem("id_std")}
                  </h6> */}
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classNames(classes.description, classes.textCenter, classes.textBlue)}>
            <p>
              Modifier les caractéristiques et photos du produit, consultez la fiche du produit.
            </p>
          </div>
          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="info"
              tabs={[
                {
                  tabButton: "Modifier le produit",
                  tabIcon: ArtTrack,
                  tabContent: (
                    <div>
                      {isitem.map((item) => (
                        <>
                          <form className={classes.form} onSubmit={updateToProduct}>
                            <GridContainer justify="center">
                              <GridItem
                                xs={10}
                                sm={10}
                                md={10}
                                className={classes.gridItem}
                              >
                                <div id="showItemId"></div>
                                <h4 className={classes.title}>DESIGNATION</h4>
                                <Badge color="info"><span className={classes.textBadge}> {item.libelle}</span></Badge>
                                <br /><br />
                                <TextField className={classes.input}
                                  type="text"
                                  fullWidth
                                  id="designationLabel"
                                  color="primary"
                                  name="designationLabel"
                                  onChange={onChangeValue}
                                  value={islibelle}
                                  autoComplete="off"
                                  required
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={10}
                                sm={10}
                                md={10}
                                className={classes.gridItem}>
                                <h4 className={classes.title}>FAMILLE</h4>
                                <Badge color="info"><span className={classes.textBadge}> {item.groupe}</span></Badge>
                                <br /><br />
                                <SectionGroupe />
                                <Muted>
                                  <h4 className={classes.title}>DESCRIPTION</h4>
                                </Muted>
                                <TextareaAutosize className={classes.textAreaWithStyle}
                                  type="text"
                                  fullWidth
                                  id="descriptionArea"
                                  color="primary"
                                  name="descriptionArea"
                                  variant="standard"
                                  onChange={onChangeValue}
                                  label="Description"
                                  placeholder={item.description}
                                  autoComplete="off"
                                  required
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer className={classes.textCenter}>
                              <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.title}>
                                  <h4 className={classes.title}>DIMENSIONS</h4>
                                </div>
                                <Badge color="info"><span className={classes.textBadge}>Hauteur en cm : {item.unitheight}</span></Badge>
                                <Badge color="info"><span className={classes.textBadge}>Largeur en cm : {item.unitwidth}</span></Badge>
                                <Badge color="info"><span className={classes.textBadge}>Profondeur en cm : {item.unitdeph}</span></Badge>
                                {/* <Badge color="info"><span className={classes.textBadge}>Volume en m3 : {item.unitvolume}</span></Badge> */}
                              </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                              <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                              >  <br /><br />
                                <SectionSliderUnit />
                              </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                              <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                className={classes.gridItem}
                              >
                                <div className={classes.textCenter}>
                                  <Button color="info" round onClick={updateToProduct}>Enregistrer les modifications
                                  </Button>
                                  <Button round color="danger"
                                    startIcon={<CachedIcon />}
                                    onClick={e => {
                                      getItemData();
                                    }}
                                  >
                                    Visualiser
                                  </Button>
                                  <br />
                                  <h6>{messageMaj}</h6>
                                </div>
                              </GridItem>
                            </GridContainer>
                          </form>
                          <br /><br />
                      <GridContainer>
                        <GridItem xs={12}
                          sm={12}
                          md={12}>
                          <Card product plain>
                            <CardBody className={classes.textCenter} plain>
                              <div>
                                <h4 className={classes.textCaramel}>Modifier le nombre d'article et enregistrez-le</h4>
                                <Compteur
                                  id_std={sessionStorage.getItem("id_std")}
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                          <form className={classes.form} onSubmit={updatePriceToProduct}>
                            <GridContainer className={classes.textCenter}>
                              <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.title}>
                                  <h4 className={classes.title}>PRIX</h4>
                                </div>
                                <Badge color="info"><span className={classes.textBadge}>Collaborateur : {item.unithtprivate} € HT</span></Badge>
                                <Badge color="info"><span className={classes.textBadge}>Public : {item.unitht} € HT</span></Badge>
                                <Badge color="info"><span className={classes.textBadge}>Discount : {item.discount} %</span></Badge>
                                 <Badge color="info"><span className={classes.textBadge}>TVA : {item.tva} %</span></Badge> 
                              </GridItem>
                            </GridContainer>
                            <GridContainer justify="center">
                              <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                              >  <br /><br />
                                <SectionPriceProduct />
                              </GridItem>
                            </GridContainer>

                            <GridContainer justify="center">
                              <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                className={classes.gridItem}
                              >
                                <div className={classes.textCenter}>
                                  <Button color="info" round onClick={updatePriceToProduct}>Enregistrer les prix
                                  </Button>
                                  <Button round color="danger"
                                    startIcon={<CachedIcon />}
                                    onClick={e => {
                                      getItemData();
                                    }}
                                  >
                                    Visualiser
                                  </Button>
                                  <br />
                                  <h6>{messageMaj}</h6>
                                </div>
                              </GridItem>
                            </GridContainer>
                          </form>

                          

                          <div className={classes.productPage}>
                            <div className={classes.main}>
                              <GridContainer justify="center">
                                <GridItem md={6} sm={6}>
                                  <ImageGallery
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                                    ref={position}
                                    items={images}
                                    showThumbnails={true}
                                    renderLeftNav={(onClick, disabled) => {
                                      return (
                                        <button
                                          className='image-gallery-left-nav'
                                          disabled={disabled}
                                          onClick={onClick}
                                        />
                                      );
                                    }}
                                    renderRightNav={(onClick, disabled) => {
                                      return (
                                        <button
                                          className='image-gallery-right-nav'
                                          disabled={disabled}
                                          onClick={onClick}

                                        />
                                      );
                                    }}
                                    renderCustomControls={(onClick) => {
                                      if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
                                        return (
                                          <Button
                                            color="transparent"
                                            size="sm"
                                            round
                                            onClick={() => getItemRotate(sessionStorage.getItem("id_std"), sessionStorage.getItem("ctr_index"))}
                                          ><RotateRightIcon /></Button>
                                        );
                                      }
                                    }}
                                  />

                                </GridItem>
                              </GridContainer>
                            </div>
                          </div>

                          <br />
                          <GridContainer className={classes.textCenter}>
                            <GridItem md={12} sm={12}>
                              <Button round color="danger"
                                startIcon={<CachedIcon />}
                                onClick={e => {
                                  getPicturesData();
                                }}
                              >
                                Recharger les photos
                              </Button> </GridItem>
                          </GridContainer>

                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              sm={12}
                              md={10}
                              className={classes.gridItem}
                            >
                              <h4 className={classes.title}>PHOTOS</h4>

                              <GridContainer justify="center">
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={3}
                                  className={classes.gridItem}
                                >
                                  <ImageUpload id='1'
                                    addButtonProps={{ round: true }}
                                    changeButtonProps={{ round: true }}
                                    removeButtonProps={{ round: true, color: "danger" }}
                                    uploadButtonProps={{ round: true, color: "info" }}
                                  />
                                </GridItem>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={3}
                                  className={classes.gridItem}
                                >
                                  <ImageUpload id='2'
                                    addButtonProps={{ round: true }}
                                    changeButtonProps={{ round: true }}
                                    removeButtonProps={{ round: true, color: "danger" }}
                                    uploadButtonProps={{ round: true, color: "info" }}
                                  /></GridItem>
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={3}
                                  className={classes.gridItem}
                                >
                                  <ImageUpload id='3'
                                    addButtonProps={{ round: true }}
                                    changeButtonProps={{ round: true }}
                                    removeButtonProps={{ round: true, color: "danger" }}
                                    uploadButtonProps={{ round: true, color: "info" }}
                                  /></GridItem>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>

                          <form className={classes.form} onSubmit={addToProduct}>
                            <br /><br />
                            <GridContainer className={classes.textCenter}>
                              <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.title}>
                                  <h4 className={classes.title}>COMPLEMENT</h4>
                                </div>
                                <Badge color="info"><span className={classes.textBadge}>Couleur : {item.couleur}</span></Badge>
                                <Badge color="info"><span className={classes.textBadge}>Materiau : {item.materiau}</span></Badge>
                                <Badge color="info"><span className={classes.textBadge}>Finition : {item.finition}</span></Badge>
{/*                                 <Badge color="info"><span className={classes.textBadge}>Fournisseur : {item.fournisseur}</span></Badge>
 */}                                <Badge color="info"><span className={classes.textBadge}>Gamme : {item.gamme}</span></Badge>
{/*                                 <Badge color="info"><span className={classes.textBadge}>Fabricant : {item.fabricant}</span></Badge>
 */}                              </GridItem>
                            </GridContainer>
                            <br /><br />

                            <GridContainer className={classes.pickSize}>
                              <GridItem md={6} sm={6}>
                                <SectionColor />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <SectionMateriau />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <SectionFinition />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <SectionFournisseur />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <SectionMarque />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                              <SectionType /> 
                              </GridItem>
                            </GridContainer>

                            <GridContainer justify="center">
                              <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                className={classes.gridItem}
                              >
                                <div className={classes.textCenter}>
                                  <br />
                                  <Button color="info" round onClick={addToProduct}>ENREGISTRER
                                  </Button>
                                  <Button round color="danger"
                                    startIcon={<CachedIcon />}
                                    onClick={e => {
                                      getItemData();
                                    }}
                                  >
                                    Visualiser
                                  </Button>
                                  <br />
                                  <h6>{messageUpdate}</h6>
                                </div>
                              </GridItem>
                            </GridContainer>
                          </form>
                          <br /><br />
                      <form className={classes.form} onSubmit={addToQualityProduct}>
                        <br /><br />
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.gridItem}
                          >
                            <h4 className={classes.textCaramel}>Qualifiez votre fiche produit avec l'aide des listes déroulantes ci-dessous</h4>
                             <br />
                            <GridContainer className={classes.pickSize}>
                              <GridItem md={6} sm={6}>
                                <SectionProprietaire />
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <SectionCategorie />
                              </GridItem>
                            
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.gridItem}
                          >

                            <div className={classes.textCenter}>
                              <br />
                              <Button color="info" round onClick={addToQualityProduct}>
                                ENREGISTRER
                              </Button>
                              <h6>{messageUpdateQuality}</h6>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                          <GridContainer className={classes.pullRight}>
                            <GridItem xs={12} sm={12} md={12}>
                              <Button justIcon
                                round sm color="info"
                                onClick={e => smoothScrollClickTarget(e, "showItemId")}
                              ><ArrowForwardIcon /></Button>
                            </GridItem> </GridContainer>
                          <br />
                        </>
                      ))}
                    </div>
                  )
                },
                {
                  tabButton: "Supprimer le produit",
                  tabIcon: DeleteForeverIcon,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.textCenter}>
                          {/* <Link to="/catalogue-page"> */}

                          <Button round color="danger"
                            onClick={e => {
                              delItemData();
                            }}>
                            Suppression définitive
                          </Button>
                          <br />
                          <h6>{messageNotDelete}</h6>

                          {/* </Link> */}

                        </div>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  tabButton: "Consulter la fiche du produit",
                  tabIcon: Camera,
                  tabContent: (
                    // <GridContainer justify="center">
                    //   <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.productPage}>
                      {isitem.map((item) => (
                        <div className={classes.main}>
                          <GridContainer>
                            <GridItem md={6} sm={6}>
                              <ImageGallery
                                showFullscreenButton={false}
                                showPlayButton={false}
                                startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                                items={images}
                                showThumbnails={true}
                                renderLeftNav={(onClick, disabled) => {
                                  return (
                                    <button
                                      className='image-gallery-left-nav'
                                      disabled={disabled}
                                      onClick={onClick}
                                    />
                                  );
                                }}
                                renderRightNav={(onClick, disabled) => {
                                  return (
                                    <button
                                      className='image-gallery-right-nav'
                                      disabled={disabled}
                                      onClick={onClick}

                                    />
                                  );
                                }}
                              /*   renderCustomControls={(onClick) => {
                                  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
                                    return (
                                      <Button
                                        color="transparent"
                                        size="sm"
                                        round
                                        onClick={() => getItemRotate(sessionStorage.getItem("id_std"))} >
                                      <RotateRightIcon /></Button>
                                    );
                                  }
                                }}*/
                              />

                            </GridItem>
                            <GridItem md={6} sm={6}>
                              <h2 className={classes.title}>{item.libelle}</h2>
                              <h3 className={classes.mainPrice}>{item.groupe}</h3>
                              <Accordion
                                active={0}
                                activeColor="info"
                                collapses={[
                                  {
                                    title: "Description",
                                    content: (
                                      <p> {item.description}
                                      </p>
                                    )
                                  }
                                ]}
                              />
                              <Accordion
                                active={0}
                                activeColor="info"
                                collapses={[
                                  {
                                    title: "Informations",
                                    content: (
                                      <ul>
                                        <li>Hauteur en cm : {item.unitheight}</li>
                                        <li>Largeur en cm : {item.unitwidth}</li>
                                        <li>Profondeur en cm : {item.unitdeph}</li>
                                        {/*  <li>Volume en m3 : {item.unitvolume}</li> */}
                                      </ul>
                                    )
                                  }
                                ]}
                              />
                              <Accordion
                                active={0}
                                activeColor="info"
                                collapses={[
                                  {
                                    title: "Complément",
                                    content: (
                                      <ul>
                                        <li>Couleur : {item.couleur}</li>
                                        <li>Materiau : {item.materiau}</li>
                                        <li>Finition : {item.finition}</li>
                                       {/*  <li>Fournisseur : {item.fournisseur}</li> */}
                                        <li>Gamme : {item.gamme}</li>
{/*                                         <li>Fabricant : {item.fabricant}</li>
 */}                                      </ul>
                                    )
                                  }
                                ]}
                              />
                              <br /><br />
                              <GridContainer className={classes.pullRight}>
                                <Link to="/catalogue-page">
                                  <Button round color="info"
                                    endIcon={<ArrowForwardIcon />}
                                  >
                                    Catalogue
                                  </Button>
                                </Link>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        </div>
                      )
                      )}
                    </div>
                  )
                }
              ]}
            />
          </div>  <Clearfix />
        </div>
      </div>
      <br /><br />
    </div>
  );
}
