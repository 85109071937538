/*eslint-disable*/
import React from "react";
// @material-ui/core components
import ApiConfig from "_config/ApiConfig";

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

import iframePageStyle from "views/IframePage/iframePageStyle.js";

const useStyles = makeStyles(iframePageStyle);

export default function IframePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <Header
        absolute
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />

      <iframe className={classes.iframeStyle}
        height="800px"
        width="100%"
        src={`${ApiConfig.baseUrl}/contact.php`}
      />


      <Footer
        className={classes.footer}
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/"
                    className={classes.block}
                  >
                    EFLEX
                  </a>
                </ListItem>

                 <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://eflex.lemajordome.fr/CGV_EFLEX.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGV
                  </a>
                </ListItem>
                       <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://eflex.lemajordome.fr/CGU_EFLEX.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://eflex.lemajordome.fr/PDC_EFLEX.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    Politique de Confidentialité
                  </a>
                </ListItem>
              </List>
            </div>
             <div className={classes.right}>LE MAJORDOME
              &copy; {1900 + new Date().getYear()} , {" "}
             {/*  <a
                href="https://www.artwebprod.com"
                target="_blank"
                rel="noopener noreferrer"
              > */}
               Réalisation ARTWEB-PRODUCTION
             {/*  </a> */}
            </div>
          </div>
        }
      />
    </div>

  );
}