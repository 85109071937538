import React, { useEffect, useState, useRef } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
//import { Link } from "react-router-dom";

import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import ShoppingCart from "@material-ui/icons/ShoppingCart";

//import Badge from "components/Badge/Badge.js";
//import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
//import Favorite from "@material-ui/icons/Favorite";
//import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
//import Remove from "@material-ui/icons/Remove";
//import Add from "@material-ui/icons/Add";
import RotateRightIcon from '@material-ui/icons/RotateRight';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
//import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import styles from "./sectionCatalogueStyle.js";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";

import Accordion from "components/Accordion/Accordion.js";
import Default from "components/ToggleVisibility/Default.js";

//import Mail from "@material-ui/icons/Mail";

import ToggleVisibility from "components/ToggleVisibility/ToggleVisibility.js";
//import ToggleVisibilityMove from "components/ToggleVisibility/ToggleVisibilityMove.js";
import ToggleVisibilityUpdate from "components/ToggleVisibility/ToggleVisibilityUpdate.js";
import ToggleVisibilityOrder from "components/ToggleVisibility/ToggleVisibilityOrder.js";


import { TextField } from '@material-ui/core/';
import Search from "@material-ui/icons/Search";
import { Link } from "react-router-dom";


/* import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Footer from "components/Footer/Footer.js"; */


//import SectionLocalisation from "views/LocalisationPage/SectionLocalisation.js";

import SectionProducts from "views/EcommercePage/Sections/SectionProducts.js";

//import Compteur from "views/CompteurPage/CompteurPage.js";

//import ListeInventory from "views/LocalisationPage/ListeInventoryPage.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
//import DefaultInput from "components/ToggleVisibility/DefaultInput.js";
//import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

import TooltipFavoris from "views/CompteurPage/TooltipFavoris.js";
//import ImageZoom from "react-image-zooom";

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const useStyles = makeStyles(styles);


export default function SectionCatalogue() {

  const classes = useStyles();


  const [messageCountArticle, resultCountArticle] = useState([]);


  const [isShowInformation, setShowInformation] = useState(2);

  const position = useRef(null);

  const [iscatalogue, setcatalogue] = useState([]);

  const [isitem, setitem] = useState([]);

  const [images, showPicture] = useState([]);

  const [isNameParameter, setNameParameter] = useState({
    nameParameter: '',
  });


  const [message, noSearchResult] = useState([]);


  const onChangeValue = (e) => {
    setNameParameter({
      ...isNameParameter,
      [e.target.name]: e.target.value
    });
  }

  //FAVORIS
  const [checked, setChecked] = useState(false);

  const resetIndexData = () => {
    sessionStorage.setItem("ctr_index", 0);//seteur a 0 dans image gallery
  }

  const [isGroupProduct, setGroupProduct] = useState([]);
  const [groupSelect, setGroupSelect] = useState();

  const [isGenreProduct, setGenreProduct] = useState([]);
  const [genreSelect, setGenreSelect] = useState();

  const [isPrixProduct, setPrixProduct] = useState([]);
  const [prixSelect, setprixSelect] = useState();

  /*  const [isFournisseurProduct, setFournisseurProduct] = useState([]);
   const [fournisseurSelect, setFournisseurSelect] = useState();
   const [isModeleProduct, setModeleProduct] = useState([]);
   const [modeleSelect, setModeleSelect] = useState(); */

  const getResearch = async (event) => {
    if (isNameParameter.nameParameter.length <= 2) {
      noSearchResult('3 caractères minimum');
    }
    else {
      noSearchResult();
    }
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`, {
        // id_pro:sessionStorage.getItem("getIdOwnerSelect"),
        designation: isNameParameter.nameParameter,
      })
        .then(res => {
          if (res.data.success === true) {
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
          }
          else {
            resultCountArticle('Aucun produit trouvé');
            return;
          }
        })
    } catch (error) { throw error; }
  };


  const handleChange = async (event) => {
    setChecked(event.target.checked);
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`, {
        byFav: event.target.checked,
      })
        .then(res => {
          if (res.data.success === true) {
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
          }
          else {
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  }

  const getSelectedChoice = async (selectChoice) => {
    console.log(selectChoice)
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`, {
        id_cat: selectChoice,
      })
        .then(res => {

          if (res.data.success === true) {
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
          }
          else {
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  /*FILTRE*/
  const getGroupProduct = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetGroupProduct.php`)
        .then(res => {
          setGroupProduct(res.data.groupeproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const getGenreProduct = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetTypeProduct.php`)
        .then(res => {
          //console.log(res.data.genreproductdata)
          setGenreProduct(res.data.genreproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const getPrixProduct = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetPrixProduct.php`)
        .then(res => {
          setPrixProduct(res.data.prixproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  /*CATALOGUE*/
  const getCatalogueData = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.get(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`)
        .then(res => {
         // console.log(res.data.query);
          if (res.data.success === true) {
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
            setChecked(false);//pour vider la checbox apres le reset
          }
          else {
            //console.log(res.data.query);
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getCatalogueDataReset = async () => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.get(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`)
        .then(res => {
          if (res.data.success === true) {
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
            setChecked(false);//pour vider la checbox apres le reset
          }
          else {
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getCatalogueDataGroup = async (id_grp) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`, {
        id_grp: id_grp,
      })
        .then(res => {
          if (res.data.success === true) {
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
          }
          else {
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })
    } catch (error) { throw error; }
  };


  const getCatalogueDataGenre = async (id_gen) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`, {
        id_gen: id_gen,
      })
        .then(res => {
          if (res.data.success === true) {
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Article(s)");
          }
          else {
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })

    } catch (error) { throw error; }
  };

  const getCatalogueDataPrix = async (id_pri) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`, {
        id_pri: id_pri,
      })
        .then(res => {
          if (res.data.success === true) {
            //console.log(res.data.query);
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
          }
          else {
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })

    } catch (error) { throw error; }
  };


  /*  const getFournisseurProduct = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetFournisseurProduct.php`)
        .then(res => {
          setFournisseurProduct(res.data.fournisseurproductdata);
          return;
        })
    } catch (error) { throw error; }
  };
  const getModeleProduct = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetModeleProduct.php`)
        .then(res => {
          //console.log(res.data.modeleproductdata)
          setModeleProduct(res.data.modeleproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const getCatalogueDataFournisseur = async (id_frn) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`, {
        id_frn: id_frn,
      })
        .then(res => {
          if (res.data.success === true) {
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
          }
          else {
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })

    } catch (error) { throw error; }
  }; 

  const getCatalogueDataModele = async (id_mod) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/eGetCatalogue.php`, {
        id_mod: id_mod,
      })
        .then(res => {
          if (res.data.success === true) {
            setShowInformation(2);
            setcatalogue(res.data.cataloguedata);
            resultCountArticle(res.data.recordCount + " Produit(s)");
          }
          else {
           
            resultCountArticle('Aucun produit trouvé');
          }
          return;
        })

    } catch (error) { throw error; }
  };
*/

  /*CHARGE les caracteristiques du produit*/
  const getItemData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eGetItem.php`, {
        id_std: id,
      })
        .then(res => {
          if (res.data.success === true) {
            setShowInformation(1);
            setitem(res.data.itemdata);//data avec l'image en position 1
            getPicturesData(id);//photo
            smoothScrollTarget("showItemId");
          }
          return;
        })
    } catch (error) { throw error; }
  };

  //CONSTRUIT LE TABLEAU IMAGE GALLERY
  const getPicturesData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eGetPictures.php`, {
        id_std: id,
      })
        .then(res => {
          if (sessionStorage.getItem("id_std")) {
            sessionStorage.removeItem("id_std");
          }
          sessionStorage.setItem("id_std", res.data.id_std);
          showPicture(res.data.picturedata);
          return;
        })
    } catch (error) { throw error; }
  };


  const getItemRotate = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eRotatePicture.php`, {
        id_std: id, position: position.current.state.currentIndex,
      })
        .then(res => {
          getCatalogueData();//recharge le catalogue par default et prends la mise à jour de l'image
          getItemData(id);
          return;
        })
    } catch (error) { throw error; }
  };

  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    //scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    targetScroll.scrollIntoView({ block: 'start' });
  };



  /*   
  const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };
  
    const scrollGo = (element, to, duration) => {
      var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;
  
      var animateScroll = function () {
        currentTime += increment;
        var val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    };
  
    const smoothScrollClickTarget = (e, target) => {
      e.preventDefault();
      var targetScroll = document.getElementById(target);
      scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    };
   */
  useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    getCatalogueData();//charge le catalogue par default
    getGroupProduct();//charge le filtre Famille
    getGenreProduct();
    getPrixProduct();
    //getItemData(100000);//renvoi false   
    //getFournisseurProduct();//charge le filtre Fournisseur  
    // getModeleProduct();   
  }, []);

  return (
    <div id="showCatalogueId" className={classes.section}>

      <SectionProducts />
      <div className={classes.container}>
        <div id='topCatalogue' />

        <Card raisved className={classes.card}>
          <CardBody className={classes.cardBody}>

            <form className={classes.form} onSubmit={getResearch}>
              <TextField className={classes.input}
                type="text"
                id="nameParameter"
                name="nameParameter"
                variant="standard"
                onChange={onChangeValue}
                label="Désignation..."
                placeholder="Saisir le nom"
                autoComplete="off"
                required
              />
              <Button color="primary" justIcon onClick={getResearch}>
                <Search className={classes.icons} />
              </Button>
              {"\u00A0"}{"\u00A0"}{"\u00A0"}
              <Link to="/treatmentcart-page">
                <Button className={classes.buttonBleu} justIcon>
                  <ShoppingCart className={classes.icons} />
                </Button>
              </Link>
              <h6 className={classes.textWarning}>{message}</h6>

            </form>

            <hr></hr>
            <GridContainer className={classes.pickSize}>
              <GridItem md={2} sm={2}>
                <label classes={{ label: classes.label, root: classes.labelRoot }}>
                  <input
                    type="radio"
                    name="Qualite"
                    value={1}
                    onClick={() => { getSelectedChoice(1) }}
                  />
                  <span className={classNames(classes.textMLV, classes.textBolder)}> Nouveauté</span>
                </label>
              </GridItem>
              <GridItem md={2} sm={2}>
                <label classes={{ label: classes.label, root: classes.labelRoot }}>
                  <input
                    type="radio"
                    name="Qualite"
                    value={2}
                    onClick={() => { getSelectedChoice(2) }}
                  /> <span className={classNames(classes.textMLV, classes.textBolder)}> Promo</span>

                </label>
              </GridItem>
              <GridItem md={2} sm={2}>
                <label classes={{ label: classes.label, root: classes.labelRoot }}>
                  <input
                    type="radio"
                    name="Qualite"
                    value={3}
                    onClick={() => { getSelectedChoice(3) }}
                  /> <span className={classNames(classes.textMLV, classes.textBolder)}> Solde</span>
                </label>
              </GridItem>
            </GridContainer>
            <hr></hr>
            <GridContainer className={classes.pickSize}>
              <GridItem md={2} sm={2}>
                <div className={classes.containerForm}>
                  <label className={classNames(classes.textEflex, classes.textBolder)}>Famille</label>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <Select defaultValue=""
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={groupSelect}
                      onChange={(event) => { getCatalogueDataGroup(event.target.value) }}
                      inputProps={{
                        name: "groupSelect",
                        id: "groupSelect"
                      }}
                    >
                      {isGroupProduct.map((item) => (
                        <MenuItem key={item.id_grp}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={item.id_grp}
                        >
                          {item.name_grp}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </GridItem>
              <GridItem md={2} sm={2}>
                <div className={classes.containerForm}>
                  <label className={classNames(classes.textEflex, classes.textBolder)}>Type</label>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <Select defaultValue=""
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={genreSelect}
                      onChange={(event) => { getCatalogueDataGenre(event.target.value) }}
                      inputProps={{
                        name: "genreSelect",
                        id: "genreSelect"
                      }}
                    >
                      {isGenreProduct.map((itemG) => (
                        <MenuItem key={itemG.id_gen}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={itemG.id_gen}
                        >
                          {itemG.name_gen}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </GridItem>

              <GridItem md={2} sm={2}>
                <div className={classes.containerForm}>
                  <label className={classNames(classes.textEflex, classes.textBolder)}>Prix</label>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <Select defaultValue=""
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={prixSelect}
                      onChange={(event) => { getCatalogueDataPrix(event.target.value) }}

                      inputProps={{
                        name: "prixSelect",
                        id: "prixSelect"
                      }}
                    >
                      {isPrixProduct.map((itemP) => (
                        <MenuItem key={itemP.id_pri}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={itemP.id_pri}
                        >
                          {itemP.name_pri}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </GridItem>
              <GridItem md={1} sm={1}>
                <div className={classes.smallTextSize} >
                  {sessionStorage.getItem("statut_clt") === 'modeAdmin' || sessionStorage.getItem("statut_clt") === 'modeClient' ?
                    <>{"Favoris"}
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                        className={classes.inputtypecheckbox}
                      />
                    </>
                    :
                    <></>
                  }
                </div>
              </GridItem>
              <GridItem md={2} sm={2}>
                <div className={classNames(classes.elementRight, classes.pullRight)}>

                  <Button round className={classes.buttonBleu}

                    onClick={() => getCatalogueDataReset()}

                  >reset<RotateLeftIcon />
                  </Button>
                </div>
              </GridItem>

              {/*  
           {/*  <GridItem md={2} sm={2}>
              <div className={classes.containerForm}>
                <label>Fournisseur</label>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select defaultValue=""
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={fournisseurSelect}
                    onChange={(event) => { getCatalogueDataFournisseur(event.target.value) }}

                    inputProps={{
                      name: "fournisseurSelect",
                      id: "fournisseurSelect"
                    }}
                  >
                    {isFournisseurProduct.map((itemF) => (
                      <MenuItem key={itemF.id_frn}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={itemF.id_frn}
                      >
                        {itemF.name_frn}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </GridItem> 
          
          <GridItem md={2} sm={2}>
              <div className={classes.containerForm}>
                <label>Modèle</label>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select defaultValue=""
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={modeleSelect}
                    onChange={(event) => { getCatalogueDataModele(event.target.value) }}
                    inputProps={{
                      name: "modeleSelect",
                      id: "modeleSelect"
                    }}
                  >
                    {isModeleProduct.map((itemM) => (
                      <MenuItem key={itemM.id_mod}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={itemM.id_mod}
                      >
                        {itemM.name_mod}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </GridItem> */}


            </GridContainer>
          </CardBody>
        </Card>
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.gridItem}
          >
          </GridItem>
        </GridContainer>
        <ToggleVisibility>
          <Default />
        </ToggleVisibility>
        <div className={classes.textCenter}>
          <br />
          <h3 className={classNames(classes.textMLV, classes.textBolder)}>{messageCountArticle}</h3>
        </div>
        <GridContainer>

          {iscatalogue.map((item) => (
            <GridItem xs={12} sm={6} md={4} key={item.id_std}>
              <div id={"cat_" + item.id_std} />
              <Card product plain className={classes.shadowForCard}>
                <CardHeader image plain className={classes.textCenter}>
                  <Zoom>
                    <div
                      aria-label="eFLEX"
                      role="img"
                      style={{
                        backgroundColor: '#fff',
                        backgroundImage: `url(${ApiConfig.baseUrl}/images/${item.image})`,
                        backgroundPosition: '50%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: '0',
                        paddingBottom: '80%',
                        width: '100%',
                      }}
                    />
                  </Zoom>

                  {/*    <div className={classNames(classes.textCenter, classes.imageFist)}
                    style={{
                      // backgroundImage: `url(${ApiConfig.baseUrl}/images/thumbs/${item.image})`,
                      backgroundImage: `url(${ApiConfig.baseUrl}/images/${item.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      opacity: "1"
                    }}
                  >
                    <a href="#showItemId" onClick={() => { getItemData(item.id_std); resetIndexData(); }}>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </a>
                  </div> */}


                  {/*  <div className={classNames(classes.textCenter, classes.imageFit)}>

                 <img className={classes.imageFit2} src={`${ApiConfig.baseUrl}/images/${item.image}`} />
                      <ImageZoom className={classes.imageFit2} src={`${ApiConfig.baseUrl}/images/${item.image}`} zoom="200" /> 
                    
                  </div>  */}

                </CardHeader>
                <CardBody className={classNames(classes.textCenter, classes.elementPadding)} plain>
                  <a href="#showItemId" onClick={() => { getItemData(item.id_std); resetIndexData(); }}>
                    <p className={classes.textBolder}>REF : {item.refArticle}  <span className={classNames(classes.textEflex, classes.textBolder)}>{item.categorie}</span></p>

                    <h4 className={classNames(classes.textMLV, classes.textBolder)}>{item.libelle}</h4>

                    <p className={classes.textBolder}>Famille : {item.famille}</p>
                    {/*     <p className={classes.textBolder}>Fournisseur: {item.fournisseur}</p> */}
                    <p className={classes.textBolder}>
                      {item.unitheight !== '' ? <>H : {item.unitheight} </> : <></>}
                      {item.unitwidth !== '' ? <> L : {item.unitwidth} </> : <></>}
                      {item.unitdeph !== '' ? <> P : {item.unitdeph} </> : <></>}

                    </p> </a>
                  <div>
                    <div className={classes.mlAuto}>
                      <span className={classNames(classes.textEflex, classes.textBolder)}>
                        Total : {item.dispoArticle} Article(s)
                      </span>
                      <h4 className={classNames(classes.line, classes.textBolder)}>{item.selectFormatPriceBefore}</h4>
                      <h4 className={classNames(classes.textEflex, classes.textBolder)}>{item.selectFormatPrice}</h4>

                      <TooltipFavoris
                        id_std={item.id_std}
                        checkFav={item.checkFav}
                      />

                    </div>
                  </div>
                </CardBody>
                <CardFooter plain>
                  <p className={classes.textJustify}>
                    {/*  {item.description} */}
                  </p>
                </CardFooter>
              </Card>
              {/* </div> */}
            </GridItem>
          ))}
        </GridContainer>

        <div id="showItemId" className={classes.spacePaddingL} ></div>
        {isShowInformation === 1 ? (
          <div className={classes.productPage}>
            {isitem.map((itemP) => (
              <div className={classes.ItemPage}>
                {/* <div className={classNames(classes.section_, classes.sectionGray)}> */}
                <div className={classNames(classes.container, classes.shadowForBox_)}>
                  {/*                   <div className={classNames(classes.container, classes.mainRaised)}>
 */}

                  <GridContainer>
                    <GridItem md={6} sm={6}>

                      <ImageGallery
                        showFullscreenButton={false}
                        showPlayButton={false}
                        startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                        ref={position}
                        items={images}
                        showThumbnails={true}
                        renderLeftNav={(onClick, disabled) => {
                          return (
                            <button
                              className='image-gallery-left-nav'
                              disabled={disabled}
                              onClick={onClick}
                            />
                          );
                        }}
                        renderRightNav={(onClick, disabled) => {
                          return (
                            <button
                              className='image-gallery-right-nav'
                              disabled={disabled}
                              onClick={onClick}

                            />
                          );
                        }}
                        renderCustomControls={(onClick) => {
                          if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
                            return (
                              <Button
                                color="transparent"
                                size="sm"
                                round
                                onClick={() => getItemRotate(sessionStorage.getItem("id_std"))} >
                                <RotateRightIcon /></Button>
                            );
                          }
                        }}
                      />

                    </GridItem>
                    <GridItem md={6} sm={6}>
                      <h2 className={classes.title}>{itemP.libelle}</h2>
                      <h3 className={classes.mainPrice}>{itemP.modele}</h3>
                      <h3 className={classes.mainPrice}>{itemP.groupe}</h3>
                      <h3 className={classes.mainPrice}>Référence {itemP.ref}</h3>

                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Description",
                            content: (
                              <p> {itemP.description}
                              </p>
                            )
                          }
                        ]}
                      />
                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Informations",
                            content: (
                              <ul>
                                <li>Hauteur en cm : {itemP.unitheight}</li>
                                <li>Largeur en cm : {itemP.unitwidth}</li>
                                <li>Profondeur en cm : {itemP.unitdeph}</li>
                                <li>Volume en m3 : {itemP.unitvolume}</li>
                              </ul>
                            )
                          }
                        ]}
                      />
                      <Accordion
                        active={0}
                        activeColor="info"
                        collapses={[
                          {
                            title: "Complément",
                            content: (
                              <ul>
                                <li>Materiau : {itemP.materiau}</li>
                                <li>Couleur : {itemP.couleur}</li>
                                <li>Finition : {itemP.finition}</li>
                                {/*   <li>Fournisseur : {itemP.fournisseur}</li> */}
                                <li>Gamme : {itemP.gamme}</li>
                                {/*                                 <li>Fabricant : {itemP.fabricant}</li>
 */}                              </ul>
                            )
                          }
                        ]}
                      />
                      <br /><br />
                      <hr />
                      <h3 className={classes.title}>{itemP.selectFormatPrice}</h3>
                      <br /><br />

                      <span className={classNames(classes.textEflex, classes.textBolder)}>
                        Situation : Villeneuve La Garenne 92390
                      </span>
                      <br /><br />
                      <GridContainer className={classes.pullRight_}>

                        <ToggleVisibilityUpdate
                          product={itemP.id_std}
                        >
                        </ToggleVisibilityUpdate>
                        <Button round className={classes.buttonEflex}
                          endIcon={<ArrowForwardIcon />}
                          onClick={() => smoothScroll('cat_' + itemP.id_std)}
                        >
                        </Button>
                        <ToggleVisibilityOrder
                          product={itemP.id_std}
                        >
                        </ToggleVisibilityOrder>

                        {/*    <ToggleVisibilityMove></ToggleVisibilityMove> */}




                      </GridContainer>
                      <br /><br />
                      <br />
                    </GridItem>
                  </GridContainer>
                  {/*                   </div>
 */}
                </div>
                {/*   </div> */}
              </div>
            )
            )}
          </div>) : (<div></div>)}

        <br /><br />
        {/*  <hr /> */}
        <br /><br />
        <GridContainer className={classes.pullRight}>
          <Button round className={classes.boutonSticky} size="sm"
            endIcon={<ArrowForwardIcon />}
            onClick={() => { smoothScroll('topCatalogue'); setShowInformation(2); }}// ON CLICK 2 FUNCTIONS
          >
          </Button>
        </GridContainer>
      </div>

    </div>
  );
}
