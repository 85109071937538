/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import DefaultInput from "components/ToggleVisibility/DefaultInput.js";

import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionFinitionPage() {

  const getFinitionProduct = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/eGetFinitionProduct.php`)
        .then(res => {
          //console.log(res.data.finitionproductdata)
          setFinitionProduct(res.data.finitionproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const [isFinitionProduct, setFinitionProduct] = useState([]);
  const [finitionSelect, setFinitionSelect] = useState([]);

  sessionStorage.setItem("getFinitionSelect", finitionSelect);

  const classes = useStyles();
  useEffect(() => {
    getFinitionProduct();
  }, []);

  return (
      <div className={classNames(classes.main, classes.mainRaised_)}>
        <div className={classes.container_}>
          <label>FINITION </label><ToggleVisibilityAddInput>
          <DefaultInput 
           valueString={'Finition'}
           />
        </ToggleVisibilityAddInput>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          >
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={finitionSelect}
              onChange={event => setFinitionSelect(event.target.value)}
              inputProps={{
                name: "finitionSelect",
                id: "finitionSelect"
              }}
            >
              {isFinitionProduct.map((item) => (
                <MenuItem key={item.id_fin}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={item.id_fin}
                >
                  {item.design_fin}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
  );
}
