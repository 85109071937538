/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Link } from "react-router-dom";
import { TextField } from '@material-ui/core/';

import conectPageStyle from "assets/jss/material-kit-pro-react/views/conectPageStyle.js";

import image from "assets/img/login.jpg";

const useStyles = makeStyles(conectPageStyle);

export default function LoginPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const history = useHistory();
  const [userInfo, setuserInfo] = useState({
    login: '',
    pass: '',
  });
 

  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }
  const [message, resultCheckAccount] = useState([]);

  const submitUser = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      sessionStorage.clear();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eCheckUser.php`, {
        userlogin: userInfo.login,
        userpass: userInfo.pass,
      })
        .then(res => {
          if(res.data.success===true) {
              sessionStorage.setItem("statut_clt",res.data.userdata.statut_clt);
              sessionStorage.setItem("id_clt",res.data.userdata.id_clt);
              sessionStorage.setItem("n4_prv", res.data.userprivilegedata['n4_prv']);
              sessionStorage.setItem("n6_prv", res.data.userprivilegedata['n6_prv']);
              sessionStorage.setItem("n7_prv", res.data.userprivilegedata['n7_prv']);
              sessionStorage.setItem("n9_prv", res.data.userprivilegedata['n9_prv']);
              sessionStorage.setItem("n10_prv", res.data.userprivilegedata['n10_prv']);
        
              history.push("/catalogue-page");
            }
             else{
              resultCheckAccount('Identification incorrecte');
          }            
          return;
        })
    } catch (error) { throw error; }
  };
  return (
    <div>
      <Header
        absolute
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            
            <GridItem xs={12} sm={12} md={4}>
              <Card>
             

                <form className={classes.form} onSubmit={submitUser}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Connexion</h4>
                  </CardHeader>
                  <CardBody signup>

                  <TextField
                      type="login"
                      id="_login"
                      name="login"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Identifiant"
                      placeholder="Saisir votre identifiant"
                     /* autoComplete="off"
                       required */
                    />
                    {/* <TextField
                      type="email"
                      id="_email"
                      name="email"
                      variant="standard"
                      onChange={onChangeValue}
                      label="E-mail"
                      placeholder="Saisir votre e-mail"
                      autoComplete="off"
                      required
                    /> */}

                    <br /><br />
                    <TextField
                      type="pass"
                      id="_pass"
                      name="pass"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Mot de passe"
                      placeholder="Saisir le mot de passe"
                      autoComplete="off"
                       /* required */
                    />
                    <br /><br />
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button type="submit" color="info" size="sm">Entrez</Button>
                    <br />
                    <h6 className={classes.textWarning}>{message}</h6>
                    <br /><br />
                    <h6 className={classes.textBlue}>Connectez-vous si vous possédez déjà un compte, avec vos identifants E-mail et Pass.</h6>
                  </div>
                </form>
                <br />
                <p className={classes.description + " " + classes.textCenter}>
                    <Link className={classes.textBlue} to="/recup-page">Mot de passe oublié</Link>
                  {/* <span className={classes.textRight}>
                    <Link className={classes.textBlue} to="/account-page">Inscription</Link></span> */}
                </p>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/"
                    className={classes.block}
                  >
                    EFLEX
                  </a>
                </ListItem>

                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://eflex.lemajordome.fr/CGV_EFLEX.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGV
                  </a>
                </ListItem>
                       <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://eflex.lemajordome.fr/CGU_EFLEX.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem>
                 <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://eflex.lemajordome.fr/PDC_EFLEX.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    Politique de Confidentialité
                  </a>
                </ListItem>
              </List>
            </div>
             <div className={classes.right}>LE MAJORDOME
              &copy; {1900 + new Date().getYear()} , {" "}
             {/*  <a
                href="https://www.artwebprod.com"
                target="_blank"
                rel="noopener noreferrer"
              > */}
               Réalisation ARTWEB-PRODUCTION
             {/*  </a> */}
            </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
