/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import SaveIcon from '@material-ui/icons/Save';

//import Muted from "components/Typography/Muted.js";
//import Header from "components/Header/Header.js";
//import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import HeaderLinks from "components/Header/HeaderLinks.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
//import { TextField } from '@material-ui/core/';
import SectionLocalisationMove from "views/LocalisationPage/SectionLocalisationMove.js";
import KillItem from "views/ItemPage/KillItem.js";
import LoadItemInventory from "views/ItemPage/LoadItemInventory.js";
//import Default from "components/ToggleVisibility/Default.js";
//import ToggleVisibility from "components/ToggleVisibility/ToggleVisibility.js";
//import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";
//import Clearfix from "components/Clearfix/Clearfix.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function LocalisationPage() {

  //PARTIE INVENTORY

  const [isInventory, setInventory] = useState([]);
  const [inventorySelect, setinventorySelect] = useState([]);

  const setOwnerInventory = () => {
if(sessionStorage.getItem("getIdOwnerSelect")){
if(sessionStorage.getItem("idLocalizationDest")){
  sessionStorage.removeItem("idLocalizationDest");
}
if(sessionStorage.getItem("idLevelDest")){
  sessionStorage.removeItem("idLevelDest");
}
if(sessionStorage.getItem("idZonDest")){
  sessionStorage.removeItem("idZonDest");
}
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eGetInventory.php`, {
        id_pro: sessionStorage.getItem("getIdOwnerSelect"),
      })
        .then(res => {
          if (res.data.success === true) {
            setInventory(res.data.inventorydata);//appel des inventaires realisés
            resultFindInventory('');
          }
          else {
            setInventory(res.data.inventorydata);
            resultFindInventory('Sélectionnez une localisation existante');
          }
          return;
        })
    } catch (error) { throw error; }

  }
  };
 /*  const reinitInventory = () => {
   // setKillInventory(0);
    //setLoadInventory(0);
  } */

  const [message, resultFindInventory] = useState([]);
   const [messageAdd, resultCheckInventory] = useState([]); 

  //const [idInventory, setKillInventory] = useState(0);
  //const [idLoadInventory, setLoadInventory] = useState(0);

  //au submit
  const updateInventorySelect = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/eCheckInventory.php`, {
        id_zon: inventorySelect,
      })
        .then(res => {
          if (res.data.checkinventorydata.success === true) {
           resultCheckInventory('Localisation sélectionnée');
           resultFindInventory('');           
            //setKillInventory(inventorySelect);
            //setLoadInventory(inventorySelect);
            sessionStorage.setItem("idLocalizationDest", res.data.checkinventorydata.id_loc);
            sessionStorage.setItem("idLevelDest", res.data.checkinventorydata.id_etg);
            sessionStorage.setItem("idZonDest", res.data.checkinventorydata.id_zon);
          }
          else {
           resultCheckInventory('');//on propose de supprimer l'inventaire
            resultFindInventory('');
        /*     sessionStorage.setItem("spotLocalization", res.data.checkinventorydata.spotLocalization);
            sessionStorage.setItem("spotLevel", res.data.checkinventorydata.spotLevel);
            sessionStorage.setItem("spotZone", res.data.checkinventorydata.spotZone); */
          }
        })

    } catch (error) { throw error; }
  };
 

  const classes = useStyles();

  useEffect(() => {
    setOwnerInventory();//appelle la liste des inventaire
  }, []);
  return (
    <div>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br /><br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <CustomTabs
                plainTabs
                headerColor="info"
                tabs={[
                  {
                    tabName: "Commencer",
                    tabContent: (
                      <>
                       <label>Saisir  une Destination </label>

                        <SectionLocalisationMove />
                       
                      </>
                    )
                  },
                  {
                    tabName: "Continuer",
                    tabContent: (
                      <div className={classNames(classes.main, classes.mainRaised_)}>
                        <div className={classes.container_}>
                          <label>Sélectionnez une destination </label>
                          <form onSubmit={updateInventorySelect}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={inventorySelect}

                                onChange={event => {
                                  setinventorySelect(event.target.value)
                                  //reinitInventory()
                                }}
                                inputProps={{
                                  name: "inventorySelect",
                                  id: "inventorySelect"
                                }}
                              >
                                {isInventory.map((item) => (
                                  <MenuItem key={item.id_zon}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={item.id_zon}
                                  >
                                    {item.design_loc + " " + item.cod_etg + " " + item.cod_zon}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Button color="info" size="sm" square="true"
                              onClick={updateInventorySelect}
                            >Valider<ArrowRightAltIcon fontSize="large" />
                            </Button>
                          </form>
                          <h6 className={classes.textWarning}>{message}</h6>
                          <h6 className={classes.textInfo}>{messageAdd}</h6> 


                          <div>
                           {/*  <LoadItemInventory
                              id_item={idLoadInventory}
                            /> */}
                           {/*  <KillItem
                              id_item={idInventory}
                            /> */}
                          </div>
                        </div>
                      </div>
                    )
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br /><br />
    </div>
  );
}
