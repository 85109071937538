/*eslint-disable*/
import React from "react";
// @material-ui/core components
import ApiConfig from "_config/ApiConfig";

import { makeStyles } from "@material-ui/core/styles";

//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Footer from "components/Footer/Footer.js";

import iframePageStyle from "views/IframePage/iframePageStyle.js";

const useStyles = makeStyles(iframePageStyle);

export default function IframePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <Header
        absolute
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />

      <iframe className={classes.iframeStyle}
        height="800px"
        width="100%"
        src={`${ApiConfig.baseUrl}/treatmentcartorder.php`}
      />
    </div>
  );
}