import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// plugin that creates slider
//import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from '@material-ui/core/';

// core components
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Accordion from "components/Accordion/Accordion.js";
// import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-kit-pro-react/views/presentationSections/sliderStyle.js";

import './Slider.css'
const useStyles = makeStyles(styles);



export default function SectionSliderUnit() {

  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [depth, setDepth] = useState();
  //const [volume, setVolume] = useState(0.1);

  const changeHeight = (event) => {
    setHeight(event.target.value);
    //console.log(event.target.value)
    sessionStorage.setItem("setHeightUnit", event.target.value);
  };
  const changeWidth = (event) => {
    setWidth(event.target.value);
    //console.log(event.target.value)
    sessionStorage.setItem("setWidthUnit", event.target.value);
  };
  const changeDepth = (event) => {
    setDepth(event.target.value);
    //console.log(event.target.value)
    sessionStorage.setItem("setDepthUnit", event.target.value);
  };
  // const changeVolume = (event) => {
  //   setVolume(event.target.value);
  //   sessionStorage.setItem("setVolumeUnit", event.target.value);
  // };
  const classes = useStyles();
  useEffect(() => {
    setHeight();
    setWidth();    
    setDepth();
  }, []);
  return (
    <div className={classes.section_}>
      <div className='box-container'>
        <h5 className={classes.textBlue}>Hauteur: {height} cm</h5>
        <TextField className={classes.input}
        // <input
          type="text"
          onChange={changeHeight}
          placeholder="0"
         /*  min={1}
          max={250}
          step={1} */
          value={height}
          // className='custom-slider'
        />          
        {/* </input> */}
        <br /><br /> <br />
        <h5 className={classes.textBlue}>Largeur: {width} cm</h5>
        <TextField className={classes.input}
        // <input
          type="text"
          onChange={changeWidth}
          placeholder="0"
          /* min={1}
          max={500}
          step={1} */
          value={width}
          // className='custom-slider'
        />
        {/* </input> */}
        <br /><br /> <br />
        <h5 className={classes.textBlue}>Profondeur: {depth} cm</h5>
        <TextField className={classes.input}
        //<input
          type="text"
          onChange={changeDepth}
          placeholder="0"
         /*  min={1}
          max={200}
          step={1} */
          value={depth}
          // className='custom-slider'
        />
        {/* </input> */}
        {/* <br /><br /> <br />        
        <h5 className={classes.textBlue}>Volume: {volume} m3</h5>
        <TextField className={classes.input}
          type="text"
          onChange={changeVolume}
          min={0.1}
          max={10}
          step={0.1}
          value={volume}
        /> */}
        <br /><br />
      </div>
    </div>
  );
}
