/*eslint-disable*/
import React, { useState } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
// import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import { Link } from "react-router-dom";
import { TextField } from '@material-ui/core/';



import accountPageStyle from "assets/jss/material-kit-pro-react/views/accountPageStyle.js";

import image from "assets/img/smartphone.jpg";

const useStyles = makeStyles(accountPageStyle);

export default function LoginPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const history = useHistory();
  const [userInfo, setuserInfo] = useState({
    name: '',
    email: '',
    pass: '',
    numeroClient: '',
  });

  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }
  const [message, resultAddAccount] = useState([]);

  const submitUser = async (event) => {
    try {
      event.preventDefault();
      event.persist();
     /*  if (!sessionStorage.getItem("getIdOwnerSelect")) {
        resultAddAccount('Commencer en renseignant un compte Client');
        return false;
      } */
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/eCreateUser.php`, {
        usersurname: userInfo.surname,
        username: userInfo.name,
        userentreprise: userInfo.entreprise,
        usermatricule: userInfo.matricule,
        userservice: userInfo.service,
        usertelephone: userInfo.telephone,
        useremail: userInfo.emailpro,
      })
        .then(res => {
          //console.log(res.data);
          if (res.data.userdata['success'] === true) {
            resultAddAccount(res.data.userdata['msg']);

            //history.push("/catalogue-page");
          } else {
            resultAddAccount(res.data.userdata['msg']);
          }
          return;
        })
    } catch (error) { throw error; }
  };

  return (
    <div>
      <Header
        absolute
        color="white"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>

          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form} onSubmit={submitUser}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Créer votre Compte Collaborateur
                      eFLEX</h4>
                    <h4 className={classes.cardTitle}>Bénéficiez du tarif collaborateur lors de vos achats</h4>
                    <h4 className={classes.cardTitle}>Renseignez un E-mail Pro valide dans ce formulaire, conservez le comme Login et Pass lors de votre premier accès</h4>
                  </CardHeader>

                  <CardBody signup>
                    <TextField className={classes.tinput}
                      type="text"
                      id="_surname"
                      name="surname"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Votre Prénom"
                      placeholder="Saisir un prénom"
                      autoComplete="off"
                      required

                    />
                    <br /><br />
                    <TextField className={classes.tinput}
                      type="text"
                      id="_name"
                      name="name"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Votre Nom"
                      placeholder="Saisir un nom"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField className={classes.tinput}
                      type="text"
                      id="_entreprise"
                      name="entreprise"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Nom de votre entreprise"
                      placeholder="Saisir une entreprise"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField className={classes.tinput}
                      type="text"
                      id="_matricule"
                      name="matricule"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Votre Matricule"
                      placeholder="Saisir un matricule"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField className={classes.tinput}
                      type="text"
                      id="_service"
                      name="service"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Nom de votre Service"
                      placeholder="Saisir un service"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField className={classes.tinput}
                      type="text"
                      id="_telephone"
                      name="telephone"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Numéro de téléphone"
                      placeholder="Saisir un numéro"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    <TextField
                      type="email"
                      id="_emailpro"
                      name="emailpro"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Votre e-mail pro"
                      placeholder="E-mail pro"
                      autoComplete="off"
                      required
                    />
                    <br /><br />
                    {/*  <TextField
                      type="pass"
                      id="_pass"
                      name="pass"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Mot de passe"
                      placeholder="Saisir un mot de passe"
                      autoComplete="off"
                      required
                    />
                     <br /><br /> */}
                    <h6 className={classes.textBlue}>En créant mon compte sur eFlex, j'accepte les conditions générales de vente et d'utilisation.</h6>
                    <br /><br />
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button href="https://eflex.lemajordome.fr/CGV_EFLEX.pdf"
                          target="_blank" size="sm" color="info">CGV</Button>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button href="https://eflex.lemajordome.fr/CGU_EFLEX.pdf"
                          target="_blank" size="sm" color="primary">CGU</Button>
                      </GridItem>
                    </GridContainer>

                  </CardBody>
                  <br /><br />
                  <div className={classes.textCenter}>
                    <Button type="submit" color="primary" size="sm">Créer mon Compte</Button>
                    <br /><br />
                    <h6 className={classes.textWarning}>{message}</h6>
                  </div>
                </form>

              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <br /><br />

      </div>
    </div>
  );
}
